zoo.controller('pluginsCtrl', ['$scope' ,'$http' , function($scope , $http){


	$scope.getAdress = function(val)
	{
		
	};

	//Change shipping price.
	$scope.$watch('checkout_type', function(){

		$parent.$scope.delivery.price = $scope.delivery.shipping_prices[$scope.checkout_type];
		$parent.$scope.delivery.free_shipping = $scope.delivery.free_shipping_prices[$scope.checkout_type];
		console.log('tests');
	}, true);


}]);