/*
|----------------------------------
|	Text block directive
|----------------------------------
*/

//Push new needed things to array
injectorPlugins.push('selectDpdAdressDirective');

var addDpdAdres = angular.module('selectDpdAdressDirective', []);

addDpdAdres.directive('mkDpdSelect', ['$http', function($http){
	return {
		restrict: 'E',
		scope: {
			mkDpdCity:'=',			
		},     
		template: 
		'<loader-block ng-show="loading"></loader-block><div class="form-group" ng-show="!loading">'+
			'<select class="form-control margin-top5" ng-model="$parent.$parent.$parent.city_dpd_id">'+
			'<option disabled selected value="-1">Izveleties adresi *</option>'+
			'<option ng-repeat="adress in dpdadresses" value="[[adress.id]]"> [[adress.title]] [[adress.address]] </option>'+
			'</select>'+
		'</div>',		
		link: function(scope, element, attrs){
			
			scope.loading = true;			

			scope.editorOptions = {
			    language: 'en',
			    uiColor: '#000000'
			};
			
			scope.$parent.$parent.$parent.city_dpd_id='-1';			

			scope.$watch('mkDpdCity', function()
			{	
				scope.getAdress();
				
			}, true);

			scope.getAdress = function()
			{
				scope.loading = true;
				 $http.get('/shop/cart/citydpd/'+scope.mkDpdCity).success(function(data){						
						scope.dpdadresses = data;
						scope.loading = false;								
						}).error(function(data){
							console.log("error get address ");
						});

			};


		}
	}
}]);