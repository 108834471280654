//This is variable where to push all needed modules
var injectorPlugins = [];

//Start angularjs
var zoo = angular.module('zooApp', injectorPlugins);

//Change confiration for angularJS
zoo.config(function($interpolateProvider){
    $interpolateProvider.startSymbol('[[').endSymbol(']]'); //Replace {{}} to [[]]
});
zoo.filter("totalPrice", function(){
    return function(items){
        var price = 0;
        if(items){
           $.each(items,function(key,value){
              price+= value.price * value.count;
           });
       }
        return price.toFixed(2);
    };
});