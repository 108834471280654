/*
|----------------------------------
|	Text block directive
|----------------------------------
*/

//Push new needed things to array
injectorPlugins.push('selectAdressDirective');

var addShop = angular.module('selectAdressDirective', []);

addShop.directive('mkSelect', ['$http', function($http){
	return {
		restrict: 'E',
		scope: {
			mkCity:'=',			
		},     
		template: 
		'<loader-block ng-show="loading"></loader-block><div class="form-group" ng-show="!loading">'+
			'<select class="form-control margin-top5" ng-model="$parent.$parent.$parent.city_veikala_id">'+
			'<option disabled selected value="-1">Izveleties adresi *</option>'+
			'<option ng-repeat="adress in adresses" value="[[adress.id]]"> [[adress.adress]] </option>'+
			'</select>'+
		'</div>',		
		link: function(scope, element, attrs){

			scope.loading = true;

			scope.editorOptions = {
			    language: 'en',
			    uiColor: '#000000'
			};

			scope.$parent.$parent.$parent.city_veikala_id='-1';

			scope.$watch('mkCity', function()
			{	
				scope.getAdress();
				
			}, true);

			scope.getAdress = function()
			{
				
				scope.loading = true;

				$http.get('/shop/cart/city/'+scope.mkCity).success(function(data){						
					scope.adresses = data;
					scope.loading = false;							
				}).error(function(data){
					console.log("error get address ");
				});

			};

		}
	}
}]);

addShop.directive('loaderBlock', [function(){
	return {
		restrict: 'E',
		template: '<div class="sk-circle">\
		  <div class="sk-circle1 sk-child"></div>\
		  <div class="sk-circle2 sk-child"></div>\
		  <div class="sk-circle3 sk-child"></div>\
		  <div class="sk-circle4 sk-child"></div>\
		  <div class="sk-circle5 sk-child"></div>\
		  <div class="sk-circle6 sk-child"></div>\
		  <div class="sk-circle7 sk-child"></div>\
		  <div class="sk-circle8 sk-child"></div>\
		  <div class="sk-circle9 sk-child"></div>\
		  <div class="sk-circle10 sk-child"></div>\
		  <div class="sk-circle11 sk-child"></div>\
		  <div class="sk-circle12 sk-child"></div>\
		</div>',
		link: function(scope, element, attr){

		}
	}
}]);