/*
|----------------------------------
|	Text block directive
|----------------------------------
*/

//Push new needed things to array
injectorPlugins.push('selectOmnivaAdressDirective');

var addAdres = angular.module('selectOmnivaAdressDirective', []);

addAdres.directive('mkOmnivaSelect', ['$http', function($http){
	return {
		restrict: 'E',
		scope: {
			mkOmnivaCity:'=',			
		},     
		template: 
		'<loader-block ng-show="loading"></loader-block><div class="form-group" ng-show="!loading">'+
			'<select class="form-control margin-top5" ng-model="$parent.$parent.$parent.city_omniva_id">'+
			'<option disabled selected value="-1">Izveleties adresi *</option>'+
			'<option ng-repeat="adress in adresses" value="[[adress.id]]"> [[adress.title]] </option>'+
			'</select>'+
		'</div>',		
		link: function(scope, element, attrs){
			
			scope.loading = true;

			scope.editorOptions = {
			    language: 'en',
			    uiColor: '#000000'
			};

			scope.$parent.$parent.$parent.city_omniva_id='-1';

			scope.$watch('mkOmnivaCity', function()
			{	
				scope.getAdress();
				
			}, true);

			scope.getAdress = function()
			{
				scope.loading = true;
				 $http.get('/shop/cart/cityomniva/'+scope.mkOmnivaCity).success(function(data){						
						scope.adresses = data;	
						scope.loading = false;							
						}).error(function(data){
							console.log("error get address ");
						});

			};


		}
	}
}]);