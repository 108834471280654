$(document).ready(function () {
  if ($('#map-canvas').length > 0)
  {
    var map;
    var elevator; 
    var myOptions = {
        zoom: 7,
        center: new google.maps.LatLng(56.8801729, 24.5440015,7),
        mapTypeId: 'terrain'
    };
    map = new google.maps.Map($('#map-canvas')[0], myOptions);

    for (i = 1; i <= Object.keys(locations).length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i][0], locations[i][1]),
        map: map
      });
    }

    $(document).on("click", ".cityField", function () {
      var geocoder;
       geocoder = new google.maps.Geocoder();
      var address = $(this)[0].id;
      geocoder.geocode( { 'address': address}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location);
          map.setZoom(11);
        } else {
          console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
    });
  }
}); 