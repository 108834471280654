//Registration validation
var validateRegistration = function(){

	$('#registrationForm').submit(function() {
	  	return validateForm($('#registrationForm .validate'));
	});

	$('#loginForm').submit(function(){
		return validateForm($('#loginForm .validate'));
	});

};

//Validate form
var validateForm = function(object)
{
	var noError = true;
	var textLineError = false;
	object.each(function(event){
		
		var info = jQuery(this);
		var text = $.trim(info.val());
		
		if(text.length == 0)
		{
			
			noError = false;
			textLineError = true;
			
			info.addClass('error-line');
			
			info.keypress(function() {
				$(this).removeClass('error-line');
			});

		}

		if(info.hasClass('validate-email'))
		{
			if(!validateEmail(text))
			{
				
				noError = false;
				toastr['error'](translate.emailWrong, translate.registration);

				info.addClass('error-line');
			
				info.keypress(function() {
					if(!validateEmail($(this).val()))
					{
						$(this).removeClass('error-line');
					}
				});

			}
		}

		if(info.hasClass('validate-password1'))
		{
			if($('.validate-password1').val() !== $('.validate-password2').val())
			{
				noError = false;
				toastr['error'](translate.passwordNotCorret, translate.registration);
			}
		}

		if(info.hasClass('validate-terms'))
		{
			if(!info.is(':checked'))
			{
				noError = false;
				toastr['error'](translate.acceptRules, translate.registration);
			}
		}

	});

	if(textLineError)
		toastr['error'](translate.fillInForm, translate.registration);

	return noError;
}

//Validate email
var validateEmail = function(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
}

//Start validation forms
$(document).ready(function () {
	validateRegistration();
});
