/* Default javascript for shop */

var changeForm = function(element1, element2){
	$('#' + element1).slideToggle("slow", function(){
		$('#' + element2).slideToggle("slow");
	});
}

$(document).on("click", ".open-AddAddressModal", function () {
    
    var address_id = $(this).data('id');
    
    $.get('/shop/profile/address-edit-ajax/'+address_id, function(data){
        $('#editAddressModal .modal-body form span').html(data);
    });

});

var changeSize = function(){
     $( ".productRow" ).each(function( index ) {
         var productRow = this;
         var productHeightCount = 0;
         var totalNumbersCols = $(productRow).children('div').children().length;
         var i=0;
         $(productRow).children('div').children().children().each(function(listItemIndex) {
             i++;
             var productHeight = parseFloat($(this).children().children('.product-text').height());
             if(productHeight > productHeightCount)
             {
               productHeightCount = productHeight;
             }
             if(totalNumbersCols == i)
             {
               $(productRow).children('div').children().children().children().children('.product-text').attr('style', 'height: '+productHeightCount+'px !important;');
             }
         });
     });
 }

 $( document ).ready(function() {
     changeSize();
     $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
       jQuery('.product-text').attr('style', 'height: auto;');//Reset all sizes to default first
       changeSize();
     });
 });

$(document).on("click", ".cityField", function () {

    var newTable = '';

    $('.' + $(this)[0].id).each(function(){
         newTable += '<tr class="shopField">' + $(this).html() + '</tr>';
    });

    $('#showTableRow').slideDown("slow")
    $('#showTableContent').html(newTable);
})

$(document).on("click", "#clearCityField", function () {

    $('#showTableRow').slideUp("slow")

})